import { createBrowserRouter } from "react-router-dom";
import App from "../App";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "date/:date",
    element: <App />,
    loader: ({ params }) => {
      return {
        date: params.date ? params.date : null,
        type: params.type ? params.type : 'quotes'
      };
    },
  },
  {
    path: "date",
    element: <App />,
    loader: ({ params }) => {
      return {
        date: params.date ? params.date : null,
        type: params.type ? params.type : 'quotes'
      };
    },
  },
  {
    path: "quote",
    element: <App />,
    loader: ({ params }) => {
      return {
        date: params.date ? params.date : null,
        type: params.type ? params.type : 'quotes'
      };
    },
  },
  {
    path: "iconic-lines/date/:date",
    element: <App />,
    loader: ({ params }) => {
      return {
        date: params.date ? params.date : null,
        type: params.type ? params.type : 'iconic-lines'
      };
    },
  },
  {
    path: "iconic-lines/date",
    element: <App />,
    loader: ({ params }) => {
      return {
        date: params.date ? params.date : null,
        type: params.type ? params.type : 'iconic-lines'
      };
    },
  },
  {
    path: "iconic-lines",
    element: <App />,
    loader: ({ params }) => {
      return {
        date: params.date ? params.date : null,
        type: params.type ? params.type : 'iconic-lines'
      };
    },
  }
]);

import React from 'react';
import './App.css';
import { CssBaseline, Container, Typography, Button } from '@mui/material';
import Quote from './Components/Quotes';
import backgroundImage from './Assets/bg.jpg'; // Add this line
import { AdsContainer } from './Components/AdComponent';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { dateFormat, getQuoteDate, getTodayOrYesterday, isToday, parseDate } from './Util/Date';
import { ILoaderData } from './Models/LoaderData';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { getInitDate, getQuote, getQuoteNo } from './Quotes/Main';
import { CUSTOM_EVENTS, GAEvent } from './Components/GoogleAnalyticsEvents';
import IconicLines from './Components/IconicLines';
import { getIconicLine, getIconicLineNo } from './IconicLines/Main';

function App() {
  const loaderData = useLoaderData() as ILoaderData;
  const date = parseDate(loaderData?.date);
  const displayDate = getTodayOrYesterday(date ? date : undefined);
  const navigate = useNavigate();

  const type = loaderData?.type ? loaderData?.type : 'quotes';
  const title = type === 'quotes' ? 'Quote of the day' : 'Iconic line of the day';
  const title2 = type === 'quotes' ? 'Inspire Daily Quotes' : 'Daily Iconic Lines';

  const detailsLine = getIconicLine(displayDate);
  const detailsQuote = getQuote(displayDate);
  let bgImage = backgroundImage;
  if (type === 'quotes' && detailsQuote && detailsQuote.picture) {
    bgImage = detailsQuote.picture;
  } else if (type === 'iconic-lines' && detailsLine && detailsLine.picture) {
    bgImage = detailsLine.picture;
  }

  const onNextClick = () => {
    const newDate = new Date(date ? date : new Date());
    newDate.setDate(newDate.getDate() + 1);
    const url = type === 'quotes' ? `/date/${getQuoteDate(newDate)}` : `/iconic-lines/date/${getQuoteDate(newDate)}`;
    GAEvent(CUSTOM_EVENTS.USERINT_DATE_CHANGE, { newDate: newDate.toISOString(), dateSlug: getQuoteDate(newDate) });
    navigate(url);
  };

  const onPrevClick = () => {
    const newDate = new Date(date ? date : new Date());
    newDate.setDate(newDate.getDate() - 1);
    const url = type === 'quotes' ? `/date/${getQuoteDate(newDate)}` : `/iconic-lines/date/${getQuoteDate(newDate)}`;
    GAEvent(CUSTOM_EVENTS.USERINT_DATE_CHANGE, { newDate: newDate.toISOString(), dateSlug: getQuoteDate(newDate) });
    navigate(url);
  };

  const isInitDate = () => {
    if (loaderData?.date) {
      return getInitDate() === loaderData?.date;
    }
    let currDate = new Date();
    return getInitDate() === getQuoteDate(currDate);
  };

  const hashNumber = type === 'quotes' ?
    getQuoteNo(getQuoteDate(date ? date : new Date()))
    : getIconicLineNo(getQuoteDate(date ? date : new Date()));

  return (
    <div className="App">
      <CssBaseline />
      <Container
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '100vh',
          flexDirection: "column",
          backgroundImage: `url(${bgImage})`, // Add background image
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          maxWidth: '100%',
          color: '#fff',
          paddingBottom: '20px'
        }}
      >
        <Link role="button" to={'/'}
          title={`Go to today's quote`}
          style={{ cursor: 'pointer', color: '#f8f8f8' }}>
          <Typography variant='h6' sx={{ m: 2 }}>
            {title2} #{hashNumber}
          </Typography>
        </Link>

        <Typography sx={{ m: 1 }} style={{
          padding: '8px',
          backgroundColor: '#f5f5f5',
          opacity: 0.8,
          color: "#000",
          borderRadius: '10px'
        }}>
          {!isInitDate() ?
            <Button title="Previous day's quote" variant='outlined' onClick={() => onPrevClick()} style={{ marginLeft: '8px', marginRight: '8px' }}><ArrowCircleLeftIcon /></Button>
            : null}

          &nbsp;{title}: {dateFormat(displayDate)}&nbsp;

          {!isToday(date ? date : new Date()) ?
            <Button title="Next day's quote" variant='outlined' onClick={() => onNextClick()} style={{ marginLeft: '8px', marginRight: '8px' }}><ArrowCircleRightIcon /></Button> :
            null}
        </Typography>

        {type === 'quotes' ? <Quote date={displayDate} /> : <IconicLines date={displayDate} />}

        <span style={{
          padding: '10px',
          backgroundColor: '#f5f5f5',
          opacity: 0.8,
          color: "#000",
          borderRadius: '10px',
          marginBottom: '10px',
        }}>
          <Link to={`/iconic-lines/date/${getQuoteDate(new Date())}`}>Today's Iconic Line</Link> &nbsp;|&nbsp;
          <Link to={`/date/${getQuoteDate(new Date())}`}>Today's Quote</Link> &nbsp;|&nbsp;
          <Link to="https://www.facebook.com/inspiredailyquotes.online/" target='_blank'><FacebookIcon /></Link> &nbsp;|&nbsp;
          <Link to="https://www.instagram.com/inspiredailyquotes.online/" target='_blank'><InstagramIcon /></Link>
        </span>

        <AdsContainer />
      </Container>
    </div >
  );
}

export default App;

export interface IIconicLines {
  date: string;
  iconicLine: string;
  author: string;
  tags: string[];
  details?: string;
  picture?: string;
  postPicture?: string;
}

export const iconicLines: { [id: string]: IIconicLines } = {
  "9_8_2024": {
    "date": "9_8_2024",
    "iconicLine": "Chew on this, you ugly bastard.",
    "author": "Jonas Taylor (The Meg 2018)",
    "tags": ["action", "horror", "the_meg", "movie"],
    "picture": "https://ca-times.brightspotcdn.com/dims4/default/1d45468/2147483647/strip/true/crop/2940x1654+450+0/resize/1200x675!/quality/75/?url=https%3A%2F%2Fcalifornia-times-brightspot.s3.amazonaws.com%2Fcd%2Ffa%2Fe28d95434cbab595d67df582050e%2Fet-meg-2-the-trench-008.JPEG",
    "details": "A group of scientists exploring the Marianas Trench encounter the largest marine predator that has ever existed - the Megalodon."
  },
  "10_8_2024": {
    "date": "10_8_2024",
    "iconicLine": "May the Force be with you.",
    "author": "Various (Star Wars)",
    "tags": ["sci-fi", "fantasy"],
    "picture": "https://mediaproxy.tvtropes.org/width/1200/https://static.tvtropes.org/pmwiki/pub/images/star_wars_logo.png",
    "details": "This iconic phrase from Star Wars has become a cultural phenomenon."
  },
  "11_8_2024": {
    "date": "11_8_2024",
    "iconicLine": "Lord Shimura: You have no honor. <br />The Ghost: And you are a slave to it.",
    "author": "Jin Sakai (Ghost Of Tsushima)",
    "tags": ["romance", "classic", "game"],
    "picture": "https://cdn.wccftech.com/wp-content/uploads/2024/03/b3iB2zf2xHj9shC0XDTULxND-HD-scaled.jpg",
    "details": "Ghost of Tsushima is an action game set in feudal Japan."
  },
  "12_8_2024": {
    "date": "12_8_2024",
    "iconicLine": "I'll be back.",
    "author": "Terminator (The Terminator)",
    "tags": ["action", "sci-fi"],
    "picture": "https://townsquare.media/site/295/files/2019/10/Terminator-Orion.jpg?w=780&q=75",
    "details": "This line became the signature catchphrase of Arnold Schwarzenegger."
  },
  "13_8_2024": {
    "date": "13_8_2024",
    "iconicLine": "Frankly, my dear, I don't give a damn.",
    "author": "Rhett Butler (Gone with the Wind)",
    "tags": ["drama", "classic"],
    "details": "Gone with the Wind, one of the most iconic films of all time, was released in 1939."
  },
  "14_8_2024": {
    "date": "14_8_2024",
    "iconicLine": "You can't handle the truth!",
    "author": "Col. Nathan R. Jessup (A Few Good Men)",
    "tags": ["drama", "courtroom"],
    "picture": "https://media.licdn.com/dms/image/C4E12AQHUL_fa1vxoNg/article-cover_image-shrink_720_1280/0/1589463548601?e=2147483647&v=beta&t=Aq2LV7aAVHkBbzW0lhAj1w_wPTcW6NNbGCOdeNTHZes",
    "details": "A powerful line delivered by Jack Nicholson in a tense courtroom drama!"
  },
  "15_8_2024": {
    "date": "15_8_2024",
    "iconicLine": "I'm the king of the world!",
    "author": "Jack Dawson (Titanic)",
    "tags": ["romance", "drama"],
    "details": "Leonardo DiCaprio shouts this line in one of Titanic's most memorable scenes."
  },
  "16_8_2024": {
    "date": "16_8_2024",
    "iconicLine": "E.T. phone home.",
    "author": "E.T. (E.T. the Extra-Terrestrial)",
    "tags": ["sci-fi", "family"],
    "details": "A touching line from the beloved film about a young boy and his alien friend."
  },
  "17_8_2024": {
    "date": "17_8_2024",
    "iconicLine": "I see dead people.",
    "author": "Cole Sear (The Sixth Sense)",
    "tags": ["thriller", "mystery"],
    "details": "A chilling line from the film that left audiences stunned with its twist ending."
  },
  "18_8_2024": {
    "date": "18_8_2024",
    "iconicLine": "Hasta la vista, baby.",
    "author": "Terminator (Terminator 2: Judgment Day)",
    "tags": ["action", "sci-fi"],
    "picture": "https://acmi-website-media-prod.s3.ap-southeast-2.amazonaws.com/media/images/Hasta_La_Vista_baby.original.jpg",
    "details": "Another iconic line from Arnold Schwarzenegger in the Terminator franchise."
  },
  "19_8_2024": {
    "date": "19_8_2024",
    "iconicLine": "There's no place like home.",
    "author": "Dorothy Gale (The Wizard of Oz)",
    "tags": ["fantasy", "family"],
    "details": "Dorothy's heartfelt realization in the classic film The Wizard of Oz."
  },
  "20_8_2024": {
    "date": "20_8_2024",
    "iconicLine": "I'm gonna make him an offer he can't refuse.",
    "author": "Vito Corleone (The Godfather)",
    "tags": ["crime", "drama"],
    "picture": "https://qph.cf2.quoracdn.net/main-qimg-480f672a523d2301977eb65d4970f2ef-lq",
    "details": "One of the most famous lines in cinema history from the iconic Godfather trilogy."
  },
  "21_8_2024": {
    "date": "21_8_2024",
    "iconicLine": "Why so serious?",
    "author": "The Joker (The Dark Knight)",
    "tags": ["action", "superhero"],
    "details": "Heath Ledger's chilling delivery as The Joker became instantly iconic."
  },
  "22_8_2024": {
    "date": "22_8_2024",
    "iconicLine": "Say hello to my little friend!",
    "author": "Tony Montana (Scarface)",
    "tags": ["crime", "drama"],
    "postPicture": "https://i.ibb.co/7NhHV0m/isq-iconiclines.png",
    "details": "Al Pacino's unforgettable performance as Tony Montana made this line legendary."
  },
  "23_8_2024": {
    "date": "23_8_2024",
    "iconicLine": "You talking to me?",
    "author": "Travis Bickle (Taxi Driver)",
    "tags": ["drama", "psychological"],
    "details": "Robert De Niro's intense delivery in Taxi Driver became an iconic moment in film."
  },
  "24_8_2024": {
    "date": "24_8_2024",
    "iconicLine": "To infinity and beyond!",
    "author": "Buzz Lightyear (Toy Story)",
    "tags": ["animation", "family"],
    "details": "Buzz Lightyear's catchphrase from Toy Story became a beloved line for all ages."
  },
  "25_8_2024": {
    "date": "25_8_2024",
    "iconicLine": "Here's Johnny!",
    "author": "Jack Torrance (The Shining)",
    "tags": ["horror", "thriller"],
    "details": "Jack Nicholson's ad-libbed line in The Shining became one of the most terrifying moments in film."
  },
  "26_8_2024": {
    "date": "26_8_2024",
    "iconicLine": "Houston, we have a problem.",
    "author": "Jim Lovell (Apollo 13)",
    "tags": ["drama", "space"],
    "details": "This line from Apollo 13 became synonymous with dealing with unexpected challenges."
  },
  "27_8_2024": {
    "date": "27_8_2024",
    "iconicLine": "I'm walking here! I'm walking here!",
    "author": "Ratso Rizzo (Midnight Cowboy)",
    "tags": ["drama", "classic"],
    "details": "Dustin Hoffman's ad-libbed line in Midnight Cowboy became a symbol of New York grit."
  },
  "28_8_2024": {
    "date": "28_8_2024",
    "iconicLine": "Carpe diem. Seize the day, boys.",
    "author": "John Keating (Dead Poets Society)",
    "tags": ["drama", "inspirational"],
    "details": "Robin Williams' character in Dead Poets Society inspires his students with this famous line."
  },
  "29_8_2024": {
    "date": "29_8_2024",
    "iconicLine": "Life is like a box of chocolates. You never know what you're gonna get.",
    "author": "Forrest Gump (Forrest Gump)",
    "tags": ["drama", "inspirational"],
    "details": "Tom Hanks' portrayal of Forrest Gump delivered this iconic and heartwarming line."
  },
  "30_8_2024": {
    "date": "30_8_2024",
    "iconicLine": "I'll have what she's having.",
    "author": "Customer (When Harry Met Sally...)",
    "tags": ["romantic", "comedy"],
    "details": "This hilarious line from When Harry Met Sally became one of the most quoted in film history."
  },
  "31_8_2024": {
    "date": "31_8_2024",
    "iconicLine": "I am your father.",
    "author": "Darth Vader (Star Wars: The Empire Strikes Back)",
    "tags": ["sci-fi", "fantasy"],
    "details": "One of the greatest plot twists in film history, revealing the true relationship between Luke and Vader."
  },
  "1_9_2024": {
    "date": "1_9_2024",
    "iconicLine": "You shall not pass!",
    "author": "Gandalf (The Lord of the Rings: The Fellowship of the Ring)",
    "tags": ["fantasy", "adventure"],
    "details": "Gandalf's iconic stand against the Balrog in The Lord of the Rings."
  },
  "2_9_2024": {
    "date": "2_9_2024",
    "iconicLine": "There's no crying in baseball!",
    "author": "Jimmy Dugan (A League of Their Own)",
    "tags": ["sports", "comedy"],
    "details": "Tom Hanks' exasperated line became one of the most quoted in sports films."
  },
  "3_9_2024": {
    "date": "3_9_2024",
    "iconicLine": "I feel the need—the need for speed!",
    "author": "Maverick (Top Gun)",
    "tags": ["action", "adventure"],
    "details": "Tom Cruise's character Maverick utters this iconic line in Top Gun."
  },
  "4_9_2024": {
    "date": "4_9_2024",
    "iconicLine": "You're gonna need a bigger boat.",
    "author": "Chief Brody (Jaws)",
    "tags": ["thriller", "suspense"],
    "details": "Roy Scheider's famous line in Jaws as the shark looms closer."
  },
  "5_9_2024": {
    "date": "5_9_2024",
    "iconicLine": "Keep your friends close, but your enemies closer.",
    "author": "Michael Corleone (The Godfather Part II)",
    "tags": ["crime", "drama"],
    "details": "This strategic advice from Michael Corleone became a widely quoted line."
  },
  "6_9_2024": {
    "date": "6_9_2024",
    "iconicLine": "It's alive! It's alive!",
    "author": "Dr. Frankenstein (Frankenstein)",
    "tags": ["horror", "classic"],
    "details": "This line from Frankenstein marked one of the most iconic moments in horror cinema."
  },
  "7_9_2024": {
    "date": "7_9_2024",
    "iconicLine": "My precious.",
    "author": "Gollum (The Lord of the Rings: The Two Towers)",
    "tags": ["fantasy", "adventure"],
    "details": "Gollum's obsession with the One Ring is perfectly captured in this line."
  },
  "8_9_2024": {
    "date": "8_9_2024",
    "iconicLine": "Bond. James Bond.",
    "author": "James Bond (Dr. No)",
    "tags": ["action", "spy"],
    "details": "The introduction of 007 in Dr. No became a hallmark of the Bond series."
  },
  "9_9_2024": {
    "date": "9_9_2024",
    "iconicLine": "I'm as mad as hell, and I'm not going to take this anymore!",
    "author": "Howard Beale (Network)",
    "tags": ["drama", "classic", "satire"],
    "details": "An iconic outburst from the film Network."
  },
  "10_9_2024": {
    "date": "10_9_2024",
    "iconicLine": "They call it a Royale with Cheese.",
    "author": "Vincent Vega (Pulp Fiction)",
    "tags": ["crime", "drama"],
    "details": "A famous conversation about cultural differences in Quentin Tarantino's Pulp Fiction."
  },
  "11_9_2024": {
    "date": "11_9_2024",
    "iconicLine": "You're killing me, Smalls!",
    "author": "Hamilton 'Ham' Porter (The Sandlot)",
    "tags": ["comedy", "family"],
    "details": "A memorable line from the beloved family film The Sandlot."
  },
  "12_9_2024": {
    "date": "12_9_2024",
    "iconicLine": "The first rule of Fight Club is: You do not talk about Fight Club.",
    "author": "Tyler Durden (Fight Club)",
    "tags": ["drama", "thriller"],
    "details": "This line from Fight Club became a catchphrase for secrecy and rebellion."
  },
  "13_9_2024": {
    "date": "13_9_2024",
    "iconicLine": "I am serious... and don't call me Shirley.",
    "author": "Dr. Rumack (Airplane!)",
    "tags": ["comedy", "classic"],
    "details": "Leslie Nielsen's deadpan delivery in Airplane! made this one of the funniest lines in film history."
  },
  "14_9_2024": {
    "date": "14_9_2024",
    "iconicLine": "Life is a banquet, and most poor suckers are starving to death!",
    "author": "Auntie Mame (Auntie Mame)",
    "tags": ["comedy", "drama"],
    "details": "Auntie Mame's vivacious outlook on life is captured in this famous line."
  },
  "15_9_2024": {
    "date": "15_9_2024",
    "iconicLine": "Yippee-ki-yay, motherf***er!",
    "author": "John McClane (Die Hard)",
    "tags": ["action", "thriller"],
    "details": "Bruce Willis' character John McClane utters this line in Die Hard, solidifying his action hero status."
  },
  "16_9_2024": {
    "date": "16_9_2024",
    "iconicLine": "If you build it, he will come.",
    "author": "The Voice (Field of Dreams)",
    "tags": ["drama", "fantasy"],
    "details": "This mysterious line from Field of Dreams became a symbol of faith and belief."
  },
  "17_9_2024": {
    "date": "17_9_2024",
    "iconicLine": "You is kind. You is smart. You is important.",
    "author": "Aibileen Clark (The Help)",
    "tags": ["drama", "inspirational"],
    "details": "Aibileen's words of encouragement in The Help became an empowering moment."
  },
  "18_9_2024": {
    "date": "18_9_2024",
    "iconicLine": "Just keep swimming.",
    "author": "Dory (Finding Nemo)",
    "tags": ["animation", "family"],
    "details": "Dory's optimistic mantra in Finding Nemo became a motivational phrase for many."
  },
  "19_9_2024": {
    "date": "19_9_2024",
    "iconicLine": "I coulda been a contender.",
    "author": "Terry Malloy (On the Waterfront)",
    "tags": ["drama", "classic"],
    "details": "Marlon Brando's lament in On the Waterfront is one of the most poignant lines in cinema."
  },
  "20_9_2024": {
    "date": "20_9_2024",
    "iconicLine": "Nobody puts Baby in a corner.",
    "author": "Johnny Castle (Dirty Dancing)",
    "tags": ["romance", "drama"],
    "details": "Patrick Swayze's line in Dirty Dancing became a declaration of love and defiance."
  },
  "21_9_2024": {
    "date": "21_9_2024",
    "iconicLine": "Keep the change, ya filthy animal.",
    "author": "Gangster Johnny (Angels with Filthy Souls in Home Alone)",
    "tags": ["comedy", "holiday"],
    "details": "A memorable line from the fictional gangster film within Home Alone."
  },
  "22_9_2024": {
    "date": "22_9_2024",
    "iconicLine": "Life moves pretty fast. If you don't stop and look around once in a while, you could miss it.",
    "author": "Ferris Bueller (Ferris Bueller's Day Off)",
    "tags": ["comedy", "coming_of_age"],
    "details": "Ferris Bueller's philosophy on life has resonated with audiences for decades."
  },
  "23_9_2024": {
    "date": "23_9_2024",
    "iconicLine": "I'm mad as hell, and I'm not going to take this anymore!",
    "author": "Howard Beale (Network)",
    "tags": ["drama", "satire"],
    "details": "This line from Network became a rallying cry for frustration with society."
  },
  "24_9_2024": {
    "date": "24_9_2024",
    "iconicLine": "You complete me.",
    "author": "Jerry Maguire (Jerry Maguire)",
    "tags": ["romance", "drama"],
    "details": "Tom Cruise's heartfelt line became one of the most quoted romantic moments in film."
  },
  "25_9_2024": {
    "date": "25_9_2024",
    "iconicLine": "Elementary, my dear Watson.",
    "author": "Sherlock Holmes (The Adventures of Sherlock Holmes)",
    "tags": ["mystery", "classic"],
    "details": "Although this line was never said by Sherlock Holmes in the original stories, it became famous in the 1939 film."
  },
  "26_9_2024": {
    "date": "26_9_2024",
    "iconicLine": "I drink your milkshake!",
    "author": "Daniel Plainview (There Will Be Blood)",
    "tags": ["drama", "classic"],
    "details": "Daniel Day-Lewis' intense performance in There Will Be Blood made this line unforgettable."
  },
  "27_9_2024": {
    "date": "27_9_2024",
    "iconicLine": "Every time a bell rings, an angel gets his wings.",
    "author": "Zuzu Bailey (It's a Wonderful Life)",
    "tags": ["holiday", "classic"],
    "details": "This line from It's a Wonderful Life has become synonymous with the holiday season."
  },
  "28_9_2024": {
    "date": "28_9_2024",
    "iconicLine": "I am Groot.",
    "author": "Groot (Guardians of the Galaxy)",
    "tags": ["action", "sci-fi"],
    "details": "Despite only saying these three words, Groot's line became a cultural phenomenon."
  },
  "29_9_2024": {
    "date": "25_9_2024",
    "iconicLine": "What we've got here is failure to communicate.",
    "author": "Captain (Cool Hand Luke)",
    "tags": ["drama", "classic"],
    "details": "This line from Cool Hand Luke became emblematic of authority and rebellion, also the first line from famous Guns and Roses track civil war."
  },
  "30_9_2024": {
    "date": "30_9_2024",
    "iconicLine": "You had me at 'hello'.",
    "author": "Dorothy Boyd (Jerry Maguire)",
    "tags": ["romance", "drama"],
    "details": "Renee Zellweger's heartfelt line in Jerry Maguire became one of the most memorable romantic moments."
  },
  "1_10_2024": {
    "date": "1_10_2024",
    "iconicLine": "Here's looking at you, kid.",
    "author": "Rick Blaine (Casablanca)",
    "tags": ["romance", "classic"],
    "details": "Casablanca is widely regarded as one of the greatest films ever made."
  }
};

import { getQuoteDate } from "../Util/Date";
import { iconicLines } from "./IconicLinesDir";

const iconicLinesKeys = Object.keys(iconicLines);

export function getInitDate() {
  return iconicLinesKeys[0];
}

export function getIconicLineNo(dateStr: string) {
  for (let i = 0; i < iconicLinesKeys.length; i++) {
    if (dateStr === iconicLinesKeys[i]) {
      return i + 1;
    }
  }
}

export function getIconicLine(date?: Date) {
  const dateStr = getQuoteDate(date);

  if (iconicLines[dateStr]) {
    return iconicLines[dateStr];
  } else {
    /* const keys = iconicLinesKeys;
    const selectedKey = keys[keys.length * Math.random() << 0]
    return quotes[selectedKey]; */
    return null;
  }
};

import { ENV_NAME } from "../Config";

/**
 *
 * @param dateStr example: "5_8_2024"
 * @returns
 */
export const parseDate = (dateStr?: string) => {
  if (!dateStr) {
    return null;
  }
  const [dd, mm, yyyy] = dateStr.split("_");
  return new Date(Date.parse(`${yyyy}-${Number(mm)}-${dd}`));
};

export function getQuoteDate(date?: Date) {
  let currDate = date || new Date();
  if (!date || isNaN(date as any)) {
    currDate = new Date();
  }
  const dateStr = `${currDate.getDate()}_${
    currDate.getMonth() + 1
  }_${currDate.getFullYear()}`;
  return dateStr;
}

export const getTodayOrYesterday = (date?: Date) => {
  if (!date || isNaN(date as any)) {
    return new Date();
  }
  const today = new Date();

  if (ENV_NAME !== "local" && date > today) {
    return today;
  }
  return date;
};

export function dateFormat(D: Date) {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = D.getDate();

  const monthIndex = D.getMonth();
  const monthName = monthNames[monthIndex];

  const year = D.getFullYear();

  return `${day} ${monthName} ${year}`;
}

export function isToday(otherDate?: Date) {
  if (!otherDate) {
    return true;
  }
  const today = new Date();
  return today.toDateString() === otherDate.toDateString();
}

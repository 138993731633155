import { useScript } from "../Util/ReactUtils";

import "./AdComponent.css";

function setOptions() {
  (window as any).atOptions = {
    'key': '8b921cfae2643bb5d76f0eec680c8474',
    'format': 'iframe',
    'height': 60,
    'width': 468,
    'params': {}
  }
  return true;
}

export function AdsContainer() {
  useScript('//www.topcreativeformat.com/8b921cfae2643bb5d76f0eec680c8474/invoke.js', '.ad-container');
  return (
    <>
      {setOptions() ? '' : ''}
      <div className="ad-container" style={{ marginBottom: 8, marginTop: 8 }}></div>
    </>
  );
}

import { getQuoteDate } from "../Util/Date";
import { quotes } from "./QuotesDir";

const quoteKeys = Object.keys(quotes);

export function getInitDate() {
  return quoteKeys[0];
}

export function getQuoteNo(dateStr: string) {
  for (let i = 0; i < quoteKeys.length; i++) {
    if (dateStr === quoteKeys[i]) {
      return i + 1;
    }
  }
}

export function getQuote(date?: Date) {
  const dateStr = getQuoteDate(date);

  if (quotes[dateStr]) {
    return quotes[dateStr];
  } else {
    /* const keys = quoteKeys;
    const selectedKey = keys[keys.length * Math.random() << 0]
    return quotes[selectedKey]; */
    return null;
  }
};
